import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ReactGA from 'react-ga';
import GA4React from "ga-4-react";

import Query from "../../components/Query";
import PAGES_QUERY from "../../queries/pages";

import Site from "../Site";
// import FourZeroFour from "../FourZeroFour";



export const RemoveTrailingSlash = ({...rest}) => {
    const location = useLocation()

    // If the last character of the url is '/'
    if (location.pathname.match('/.*/$')) {
        return <Navigate replace {...rest} to={{
            pathname: location.pathname.replace(/\/+$/, ""),
            search: location.search
        }}/>
    } else return null
}


function App() {

  if (process.env.REACT_APP_GA_VERSION === "v4") {
    const ga4react = new GA4React(process.env.REACT_APP_GA_UA_ID);

    ga4react.initialize().then((ga4) => {
      ga4.pageview('path')
      ga4.gtag('event','pageview','path') // or your custom gtag event
    },(err) => {
      console.error(err)
    });
  }

  if (process.env.REACT_APP_GA_VERSION !== "v4") {
    ReactGA.initialize({
      trackingId: process.env.REACT_APP_GA_UA_ID
    });
  }

  useEffect( () => {
    // This line will trigger on a route change
    if (process.env.REACT_APP_GA_VERSION !== "v4") {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  });

  return (
    <Query query={PAGES_QUERY} id={process.env.REACT_APP_SITE_ID}>
      {({ data: { pages } }) => {

        function rewriteRoute(slug) {
          let path = slug;

          if (slug === "home" || slug === "/" || slug === null) {
            path = "/"
          }
          else {
            path = "/" + slug
          }
          return path
        }

        return (
          <React.Fragment>
            <RemoveTrailingSlash/>
            <Routes>
              {pages.map((page, index) => (
                <Route exact={true} path={rewriteRoute(page.slug)} element={<Site props={page} />} key={index}/>
              ))}
            </Routes>
          </React.Fragment>
        )
      }}
      </Query>
  );
}

export default App;
