import React from "react";
import { NavLink } from "react-router-dom";

import styled from 'styled-components'

import { Container, Row, Col } from 'styled-bootstrap-grid';

const List = styled.div`
  display: flex;

  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;

const Item = styled.div`
  background-repeat: no-repeat;
  background: #fff;
  margin: 0.5rem;
`;

const PartnersCarousel = ({ props }) => {

  return (
    <Container>
      <Row justifyContent="center">
          <Col col lg="12" md="12" sm="12" xs="12">
            <List>
              {props.partnersCarousel_item.map((item, index) =>  (
                <NavLink to={item.link} key={index}>
                  <Item
                    style={{
                      backgroundImage: `url(${process.env.NODE_ENV !== "development" ? item.image.url
                      : process.env.REACT_APP_BACKEND_URL + item.image.url})`,
                      height: item.image.height,
                      width: item.image.width,
                    }}
                  ></Item>
                </NavLink>
              ))}
            </List>

          </Col>
      </Row>
    </Container>
  );
};

export default PartnersCarousel;
