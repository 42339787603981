import React from "react";
import { NavLink } from "react-router-dom";
import styled from 'styled-components';

import Query from "./Query";
import NAV_QUERY from "../queries/nav";

import { Container, Row, Col } from 'styled-bootstrap-grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import {faHeart} from '@fortawesome/fontawesome-free-solid';

const Wrapper = styled.div`
  background-color: ${props => props.bgColor || "#232323"};
`;

const Inner = styled.footer`
  padding: 48px 0;
  width: 100%;

  color: ${props => props.txtColor || "#232323"};
`;

const FooterTitle = styled.div`
  padding-bottom: 24px;

  font-size: 1.5rem;
  font-weight: bold;
`;
const FooterContent = styled.div`
  line-height: normal;
`;

const RightBloc = styled.div`
  margin-top: 24px;

  @media screen and (min-width: 768px) {
    margin-top: 0;

    text-align: right;
  }
`;

const Copyright = styled.div`
  margin-top: 1.5rem;
  font-size: 0.75rem;
  line-height: 2rem;
  text-align: center;

  @media screen and (min-width:768px) {
    margin-top: 0;

    text-align: left;
  }
`;

const Anchor = styled.a`
  color: ${props => props.txtColor || "#232323"};
  text-decoration: none;
`;

const Hr = styled.hr `
  border: 0;
  border-top: 1px solid ${props => props.color || "#fff"};
  margin: 48px auto;
  opacity: 0.12;
  width: 80%;

  max-width: 82%;
`;

const SocialsList = styled.ul`
  text-align: center;

  @media screen and (min-width:768px) {
    text-align: right;
  }
`;

const SocialItem = styled.li`
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px;

  vertical-align: middle;

  @media screen and (min-width:768px) {
    margin-left: 24px;
    margin-right: 0;
  }

  a {
    display: block;

    color: ${props => props.txtColor || "#fff"};
    font-size: 2rem;
  }
`;

const LegalList = styled.ul`
  margin-top: 2rem;

  text-align: center;
`;

const LegalItem = styled.li`
  display: inline-block;
  margin: 0 1rem;

  a {
    color: ${props => props.txtColor || "#fff"};
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
`;


const Footer = ({ props }) => {

  // Page sorting by order parameter
  function sortPages(a, b) {
    const pageA = a.order;
    const pageB = b.order;

    let comparison = 0;
    if (pageA > pageB) {
      comparison = 1;
    } else if (pageA < pageB) {
      comparison = -1;
    }
    return comparison;
  }

  return (
    <Wrapper bgColor={props.config.footer.background_color}>
      <Container>
        <Row>
          <Col col xl="12" lg="12" md="12" sm="12" xs="12">
            <Inner txtColor={props.config.footer.text_color}>
              <Row>
                <Col col xl="8" lg="8" md="8" sm="12" xs="12">
                  <FooterTitle dangerouslySetInnerHTML={{__html: props.Footer.baseline }} />
                  <FooterContent dangerouslySetInnerHTML={{__html: props.Footer.content}} />
                </Col>
                <Col col xl="4" lg="4" md="4" sm="12" xs="12">
                  <RightBloc>
                    {props.Footer.address_line_1 && (
                      <div>{props.Footer.address_line_1}</div>
                    )}
                    {props.Footer.address_line_2 && (
                      <div>{props.Footer.address_line_2}</div>
                    )}

                    {(props.Footer.address_zip && props.Footer.address_city) && (
                      <div>
                        {props.Footer.address_zip && (
                          <React.Fragment>{props.Footer.address_zip}</React.Fragment>
                        )}
                        {props.Footer.address_city && (
                          <React.Fragment>{props.Footer.address_city}</React.Fragment>
                        )}
                      </div>
                    )}
                    <br/>
                    <br/>
                    <br/>
                    {props.phone && (
                      <div>{props.phone}</div>
                    )}
                    {props.email && (
                      <div>{props.email}</div>
                    )}
                  </RightBloc>
                </Col>
              </Row>
              <Hr />
              <Row>
                <Col col xl="6" lg="6" md="6" sm="12" xs="12" order={2} mdOrder={1}>
                  <Copyright>Made with <FontAwesomeIcon icon={faHeart} /> by <Anchor
                    href="https://www.d-starter.fr/"
                    txtColor={props.config.footer.text_color}
                    target="_blank"
                  >d-starter.fr</Anchor></Copyright>
                </Col>
                <Col col xl="6" lg="6" md="6" sm="12" xs="12" order={1} mdOrder={2}>
                  <SocialsList>
                    {props.Footer.linkedin_url && (
                      <SocialItem txtColor={props.config.footer.text_color}>
                        <a href={props.Footer.linkedin_url} target="_blank" rel="noopener noreferrer">
                          <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                      </SocialItem>
                    )}
                    {props.Footer.facebook_url && (
                      <SocialItem txtColor={props.config.footer.text_color}>
                        <a href={props.Footer.facebook_url} target="_blank" rel="noopener noreferrer">
                          <FontAwesomeIcon icon={faFacebook} />
                        </a>
                      </SocialItem>
                    )}
                    {props.Footer.twitter_url && (
                      <SocialItem txtColor={props.config.footer.text_color}>
                        <a href={props.Footer.twitter_url} target="_blank" rel="noopener noreferrer">
                          <FontAwesomeIcon icon={faTwitter} />
                        </a>
                      </SocialItem>
                    )}
                    {props.Footer.instagram_url && (
                      <SocialItem txtColor={props.config.footer.text_color}>
                        <a href={props.Footer.instagram_url} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                        </a>
                      </SocialItem>
                    )}
                  </SocialsList>
                </Col>
              </Row>
              <Row>
                <Col col={12}>
                  <Query query={NAV_QUERY} id={process.env.REACT_APP_SITE_ID}>
                    {({ data: { pages } }) => {

                      // Filter array with is_in_nav boolean
                      var navPagesToDisplay = pages.filter(function (page) {
                        return page.is_in_footer === true;
                      });

                      // Sort nav pages
                      const sortedPages = navPagesToDisplay.sort(sortPages);

                      return (
                        <LegalList>
                          {sortedPages.map((page, i) => {
                            return (
                              <LegalItem key={i} txtColor={props.config.footer.text_color}>
                                <NavLink to={`/${page.slug}`}>{page.title}</NavLink>
                              </LegalItem>
                            )
                          })}
                        </LegalList>
                      )
                    }}
                  </Query>
                </Col>
              </Row>
            </Inner>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default Footer;
