import React from "react";
import styled from 'styled-components'

import { Container, Row, Col } from 'styled-bootstrap-grid';

const Picto = styled.img`
  background-size: contain;
  background-repeat: no-repeat;
  height: ${ props => props.iconHeight ? (props.iconHeight+"px"):("100%") };
  margin: 0 auto 16px;
  width: ${ props => props.iconWidth ? (props.iconWidth+"px"):("100%") };
`;

const Title = styled.div`
  margin-bottom: 16px;

  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  text-align: ${ props => props.textAlign || "left" }
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-align: ${ props => props.textAlign || "left" }
`;

const GridColumns = ({ props }) => {

  console.log(props);

  function widthRender(columnWidth) {
    switch(columnWidth) {
      case 'one_of_twelve':
        return '1';
      case 'two_of_twelve':
        return '2';
      case 'three_of_twelve':
        return '3';
      case 'four_of_twelve':
        return '4';
      case 'six_of_twelve':
        return '6';
      case 'height_of_twelve':
        return '8';
      case 'ten_of_twelve':
        return '10';
      case 'twelve_of_twelve':
        return '12';
      default:
        return '12';
    }
  }

  return (
    <Container>
      <Row justifyContent="center">

        {/* 
          1. On map props.Column
          2. On recupère la valeur de largeur de la colonne avec column.Width
          -- -- one_of_twelve
                two_of_twelve
                three_of_twelve
                four_of_twelve
                six_of_twelve
                height_of_twelve
                ten_of_twelve
                twelve_of_twelve
          3. On liste les composants column_title + column_text + column_image + button_primary 
          4. Pour les 3 composants title/texte/image : on recupere l'info de l'ordre "order"

         */}
         {props.Column.map((column, index) => (
          <Col col lg={widthRender(column.Width)} md="" sm="12" xs="12" key={index}>

            {column.column_item.map((item, index) => (
              <div key={index}>

                {item.column_image && (                  
                  <Picto src={ process.env.NODE_ENV !== "development" ? 
                    item.column_image.image.url 
                    : 
                    process.env.REACT_APP_BACKEND_URL + item.column_image.image.url 
                }
                  />
                )}

                {item.column_title && (
                  <Title dangerouslySetInnerHTML={{__html: item.column_title.Title}} textAlign={item.column_title.text_align} />
                )}

                {item.column_text && (
                  <Description dangerouslySetInnerHTML={{__html: item.column_text.Text}} textAlign={item.column_text.text_align} />
                )}

              </div>

            ))}

          </Col>
         ))}

{/* 
        {props.Tile.map((tile, index) =>  (
          <Col col lg="4" md="4" sm="12" xs="12" key={index}>
            <Tile>
              {tile.icon && (
                <IconImage
                  iconHeight={tile.icon_height}
                  iconWidth={tile.icon_width}
                  style={{backgroundImage: `url(${process.env.NODE_ENV !== "development"
                    ? tile.icon.url
                    : process.env.REACT_APP_BACKEND_URL + tile.icon.url})`}}
                />
              )}
              {tile.title && (
                <Title dangerouslySetInnerHTML={{__html: tile.title}} />
              )}
              {tile.description && (
                <Description dangerouslySetInnerHTML={{__html: tile.description}} />
              )}
            </Tile>
          </Col>
        ))}
*/}
      </Row>
    </Container>
  );
};

export default GridColumns;
