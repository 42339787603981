import React from "react";
import styled from 'styled-components'

import Query from "../../components/Query";

import PAGE_QUERY from "../../queries/page";

// import components
import FullImage from "../../components/full-image";
import Grid from "../../components/grid";
import GridColumns from "../../components/grid-columns";
import Hero from "../../components/hero";
import HeroDouble from "../../components/herodouble";
import Highlighted from "../../components/highlighted";
import ImagesGrid from "../../components/images-grid";
import List from "../../components/list";
import Map from "../../components/map";
import PartnersCarousel from "../../components/partners-carousel";
import PricingTable from "../../components/pricing-table";
import ResponsiveImages from "../../components/responsive-images";
import SectionButton from "../../components/section-button";
import Separator from "../../components/separator";
import Slider from "../../components/slider";
import Split from "../../components/split";
import Table from "../../components/table";
import Title from "../../components/title";
import Text from "../../components/text";


const Section = styled.section`
  background-color: ${props => props.bgProperties ? props.bgProperties.background_color : "inherit"};
  padding: 4.5rem 0;
  padding-top: ${props => props.bgProperties ? props.bgProperties.padding_top : "4.5rem"};
  padding-bottom: ${props => props.bgProperties ? props.bgProperties.padding_bottom : "4.5rem"};
  position: relative;

  color: ${props => props.bgProperties ? props.bgProperties.text_color : "inherit"};
`;

const Pattern = styled.div`

  &::before {
    content: "";
    background-image: ${props => props.bgProperties.background_pattern ? `url(${process.env.NODE_ENV !== "development" ? (props.bgProperties.background_pattern.url):(process.env.REACT_APP_BACKEND_URL + props.bgProperties.background_pattern.url)})` : "none"};
    background-repeat: ${props => props.bgProperties.background_repeat ? props.bgProperties.background_repeat : "no-repeat"};
    background-size: ${props => props.bgProperties.background_size ? props.bgProperties.background_size : "auto"};
    opacity: ${props => props.bgProperties.pattern_opacity ? props.bgProperties.pattern_opacity : "1"};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
`;

const PageBuilder = ( props ) => {

  const SectionRender = function(props) {

    return (
      <>
        {props.bloc.background_properties ? (
          <Section bgProperties={props.bloc.background_properties}>{props.children}</Section>
        ):(
          <Section>{props.children}</Section>
        )}
      </>
    )
  }

  return (
    <Query query={PAGE_QUERY} id={props.page.id}>
      {({ data: { page } }) => {

        return(
          <>
            {page.Constructor.map((bloc, index) =>  (
              <SectionRender props={props} bloc={bloc} key={index}>
                {bloc.background_properties && (
                  <Pattern bgProperties={bloc.background_properties} />
                )}

                {bloc.__typename === "ComponentBlocsHero" && (
                  <Hero site={props.site} bloc={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsHeroDouble" && (
                  <HeroDouble site={props.site} bloc={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsTitle" && (
                  <Title site={props.site} bloc={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsSeparator" && (
                  <Separator props={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsGrid" && (
                  <Grid props={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsGridColumns" && (
                  <GridColumns props={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsSplit" && (
                  <Split site={props.site} bloc={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsHighlighted" && (
                  <Highlighted site={props.site} bloc={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsMap" && (
                  <Map props={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsSlider" && (
                  <Slider props={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsText" && (
                  <Text props={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsResponsiveImages" && (
                  <ResponsiveImages props={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsImagesGrid" && (
                  <ImagesGrid props={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsPricingTable" && (
                  <PricingTable site={props.site} bloc={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsList" && (
                  <List props={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsPartnersCarousel" && (
                  <PartnersCarousel props={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsTable" && (
                  <Table props={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsFullImage" && (
                  <FullImage props={bloc} />
                )}
                {bloc.__typename === "ComponentBlocsSectionButton" && (
                  <SectionButton site={props.site} bloc={bloc} />
                )}
              </SectionRender>
            ))}
          </>
        );
      }}
    </Query>
  );
}

export default PageBuilder;
