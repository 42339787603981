import gql from "graphql-tag";

const PAGE_QUERY = gql`
  query getAllPagesAndContent($id: ID!) {
    page(id: $id) {
      id
      title
      slug
      Constructor {
        __typename
        ... on ComponentBlocsHero {
          id
          title
          subtitle
          button_primary {
            text
            text_color
            link
            icon {
              url
            }
            background_color
            background_gradient
            border_radius
            border_size
            border_style
            border_color
            box_shadow
            border_color_hover
            background_color_hover
            text_color_hover
            padding
            is_opening_modal
          }
          button_secondary {
            text
            text_color
            link
            icon {
              url
            }
            background_color
            background_gradient
            border_radius
            border_size
            border_style
            border_color
            box_shadow
            border_color_hover
            background_color_hover
            text_color_hover
            padding
            is_opening_modal
          }
          image {
            alternativeText
            url
            width
            height
          }
          image_height
          image_width
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
        }
        ... on ComponentBlocsHeroDouble {
          id
          title
          subtitle
          button_primary {
            text
            text_color
            link
            icon {
              url
            }
            background_color
            background_gradient
            border_radius
            border_size
            border_style
            border_color
            box_shadow
            border_color_hover
            background_color_hover
            text_color_hover
            padding
            is_opening_modal
          }
          button_secondary {
            text
            text_color
            link
            icon {
              url
            }
            background_color
            background_gradient
            border_radius
            border_size
            border_style
            border_color
            box_shadow
            border_color_hover
            background_color_hover
            text_color_hover
            padding
            is_opening_modal
          }
          media {
            alternativeText
            url
            width
            height
          }
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
        }
        ... on ComponentBlocsTitle {
          id
          title
          title_tag
          subtitle
          subtitle_tag
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
          button_primary {
            text
            text_color
            link
            icon {
              url
            }
            background_color
            background_gradient
            border_radius
            border_size
            border_style
            border_color
            box_shadow
            border_color_hover
            background_color_hover
            text_color_hover
            padding
            is_opening_modal
          }
          button_secondary {
            text
            text_color
            link
            icon {
              url
            }
            background_color
            background_gradient
            border_radius
            border_size
            border_style
            border_color
            box_shadow
            border_color_hover
            background_color_hover
            text_color_hover
            padding
            is_opening_modal
          }
        }
        ... on ComponentBlocsSeparator {
          id
          Color
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
        }
        ... on ComponentBlocsSplit {
          id
          title
          subtitle
          description
          direction
          image {
            url
            width
            height
          }
          button {
            text
            text_color
            link
            icon {
              url
            }
            background_color
            background_gradient
            border_radius
            border_size
            border_style
            border_color
            box_shadow
            border_color_hover
            background_color_hover
            text_color_hover
            padding
            is_opening_modal
          }
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
        }
        ... on ComponentBlocsGrid {
          id
          Tile {
            id
            icon {
              url
              width
              height
            }
            icon_height
            icon_width
            title
            description
          }
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
        }
        ... on ComponentBlocsGridColumns {
          id
          Column {
            id
            Width 
            column_item {
              column_title {
                id
                Title
                text_align
              }
              column_text {
                id
                Text
                text_align
              }
              column_image {
                id
                image {
                  url
                }
                height
                width
              }
              button_primary {
                text
                text_color
                link
                icon {
                  url
                }
                background_color
                background_gradient
                border_radius
                border_size
                border_style
                border_color
                box_shadow
                border_color_hover
                background_color_hover
                text_color_hover
                padding
                is_opening_modal
              }
            }
          }
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
        }
        ... on ComponentBlocsGridTestimony {
          id
          Testimony {
            id
            author
            message
          }
        }
        ... on ComponentBlocsHighlighted {
          id
          title
          email
          pattern {
            url
          }
          bgColor
          button {
            text
            text_color
            link
            icon {
              url
            }
            background_color
            background_gradient
            border_radius
            border_size
            border_style
            border_color
            box_shadow
            border_color_hover
            background_color_hover
            text_color_hover
            padding
            is_opening_modal
          }
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
        }
        ... on ComponentBlocsMap {
          latitude
          longitude
          zoom
          marker {
            url
            width
            height
          }
          style
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
        }
        ... on ComponentBlocsSlider {
          id
          autoPlay
          showArrows
          infiniteLoop
          transition_delay
          slide_duration
          slide {
            id
            slide {
              url
            }
          }
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
        }
        ... on ComponentBlocsText {
          content
          text_align
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
        }
        ... on ComponentBlocsResponsiveImages {
          image_desktop {
            url
          }
          image_tablet {
            url
          }
          image_mobile {
            url
          }
          link
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
        }
        ... on ComponentBlocsImagesGrid {
          items_by_line
          images {
            id
            alt_text
            image {
              url
            }
            link
          }
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
        }
        ... on ComponentBlocsPricingTable {
          columns {
            id
            icon {
              url
            }
            Title
            amount_line_one
            amount_line_two
            blocs_separator_color
            blocs {
              id
              lines {
                id
                feature
                text_color
                background_color
              }
            }
            button {
              text
              text_color
              link
              icon {
                url
              }
              background_color
              background_gradient
              border_radius
              border_size
              border_style
              border_color
              box_shadow
              border_color_hover
              background_color_hover
              text_color_hover
              padding
              is_opening_modal
            }
          }
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
        }
        ... on ComponentBlocsList {
          id
          items {
            id
            icon {
              url
              width
              height
            }
            icon_height
            icon_width
            description
          }
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
        }
        ... on ComponentBlocsPartnersCarousel {
        	id
          partnersCarousel_item {
            id
            alt
            link
            image {
              url
              width
              height
            }
          }
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
      	}
        ... on ComponentBlocsTable {
        	id
          columns {
            blocs {
              lines {
                feature
                background_color
                text_color
              }
            }
          }
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
      	}
        ... on ComponentBlocsFullImage {
          id
         	image {
          	url
        	}
          background_properties {
            full_width
            background_color
            background_pattern {
              url
            }
            background_repeat
            background_size
            text_color
            pattern_opacity
            padding_top
            padding_bottom
          }
        }
        ... on ComponentBlocsSectionButton {
          id
          button {
            text
            text_color
            link
            icon {
              url
            }
            background_color
            background_gradient
            border_radius
            border_size
            border_style
            border_color
            box_shadow
            border_color_hover
            background_color_hover
            text_color_hover
            padding
            is_opening_modal
          }
        }
      }
    }
  }
`;

export default PAGE_QUERY;
